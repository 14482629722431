import React from "react";
import SEO from "../global/Seo";
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumbs from "./Breadcrumbs";

export default function Layout({ children, pageTitle, addPadding = true }) {
  return (
    <React.Fragment>
      <SEO title={pageTitle} />
      <div className="bg-indigo-500">
        <Header />
        <Breadcrumbs pageTitle={pageTitle} />
      </div>
      <main className={`${addPadding ? "py-24" : ""}`}>{children}</main>
      <Footer />
    </React.Fragment>
  );
}

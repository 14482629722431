import * as React from "react";
import Layout from "../components/layout/Layout";

const PrivatePolicyPage = () => {
  return (
    <Layout pageTitle="Polityka prywatności">
      <section className="container mx-auto px-5">
        <h2 className="text-2xl font-bold mb-5">
          Polityka prywatności opisuje zasady przetwarzania przez nas informacji
          na Twój temat, w tym danych osobowych oraz ciasteczek, czyli tzw.
          cookies.
        </h2>
        <h2 className="text-xl font-bold mb-3">1. Informacje ogólne</h2>
        <ol className="list-decimal list-inside mb-5">
          <li>
            Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem
            url: <b>cloud-house.pl</b>
          </li>
          <li>
            Operatorem serwisu oraz Administratorem danych osobowych jest: Cloud
            Software House Kruszwicka 7
          </li>

          <li>
            Adres kontaktowy poczty elektronicznej operatora:
            kontakt@cloud-house.pl
          </li>

          <li>
            Operator jest Administratorem Twoich danych osobowych w odniesieniu
            do danych podanych dobrowolnie w Serwisie.
          </li>
          <li>Serwis wykorzystuje dane osobowe w następujących celach:</li>
          <ul className="list-disc list-inside pl-3">
            <li>Obsługa zapytań przez formularz</li>
          </ul>
          <li>
            Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i
            ich zachowaniu w następujący sposób:
            <ul className="list-disc list-inside pl-3">
              <li>
                Poprzez dobrowolnie wprowadzone w formularzach dane, które
                zostają wprowadzone do systemów Operatora.
              </li>
              <li>
                Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw.
                „ciasteczka”).
              </li>
            </ul>
          </li>
        </ol>
        <h2 className="text-xl font-bold mb-3">
          2. Wybrane metody ochrony danych stosowane przez Operatora
        </h2>
        <ol className="list-decimal list-inside mb-5">
          <li>
            Miejsca logowania i wprowadzania danych osobowych są chronione w
            warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i
            dane logowania, wprowadzone na stronie, zostają zaszyfrowane w
            komputerze użytkownika i mogą być odczytane jedynie na docelowym
            serwerze.
          </li>
          <li>
            W celu ochrony danych Operator regularnie wykonuje kopie
            bezpieczeństwa.
          </li>
          <li>
            Istotnym elementem ochrony danych jest regularna aktualizacja
            wszelkiego oprogramowania, wykorzystywanego przez Operatora do
            przetwarzania danych osobowych, co w szczególności oznacza regularne
            aktualizacje komponentów programistycznych.
          </li>
        </ol>
        <h2 className="text-xl font-bold mb-3">3. Hosting</h2>
        <ol className="list-decimal list-inside mb-5">
          <li>
            Serwis jest hostowany (technicznie utrzymywany) na serwera
            operatora: Netlify
          </li>
        </ol>
        <h2 className="text-xl font-bold mb-3">
          4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych
        </h2>
        <ol className="list-decimal list-inside mb-5">
          <li>
            W niektórych sytuacjach Administrator ma prawo przekazywać Twoje
            dane osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania
            zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na
            Administratorze. Dotyczy to takich grup odbiorców:
            <ul className="list-disc list-inside pl-3">
              <li>firma hostingowa na zasadzie powierzenia</li>
            </ul>
          </li>
          <li>
            Twoje dane osobowe przetwarzane przez Administratora nie dłużej, niż
            jest to konieczne do wykonania związanych z nimi czynności
            określonych osobnymi przepisami (np. o prowadzeniu rachunkowości). W
            odniesieniu do danych marketingowych dane nie będą przetwarzane
            dłużej niż przez 3 lata.
          </li>
          <li>
            Przysługuje Ci prawo żądania od Administratora:
            <ul className="list-disc list-inside pl-3">
              <li>dostępu do danych osobowych Ciebie dotyczących,</li>
              <li>ich sprostowania,</li>
              <li>usunięcia,</li>
              <li>ograniczenia przetwarzania,</li>
              <li>oraz przenoszenia danych.</li>
            </ul>
          </li>
          <li>
            Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania
            wskazanego w pkt 3.3 c) wobec przetwarzania danych osobowych w celu
            wykonania prawnie uzasadnionych interesów realizowanych przez
            Administratora, w tym profilowania, przy czym prawo sprzeciwu nie
            będzie mogło być wykonane w przypadku istnienia ważnych prawnie
            uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie
            interesów, praw i wolności, w szczególności ustalenia, dochodzenia
            lub obrony roszczeń.
          </li>
          <li>
            Na działania Administratora przysługuje skarga do Prezesa Urzędu
            Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.
          </li>
          <li>
            Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi
            Serwisu.
          </li>
          <li>
            W stosunku do Ciebie mogą być podejmowane czynności polegające na
            zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu
            świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia
            przez Administratora marketingu bezpośredniego.
          </li>
          <li>
            Dane osobowe są przekazywane od krajów trzecich w rozumieniu
            przepisów o ochronie danych osobowych. Oznacza to, że przesyłamy je
            poza teren Unii Europejskiej.
          </li>
        </ol>
        <h2 className="text-xl font-bold mb-3">5. Informacje w formularzach</h2>
        <ol className="list-decimal list-inside mb-5">
          <li>
            Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym
            dane osobowe, o ile zostaną one podane.
          </li>
          <li>
            Serwis może zapisać informacje o parametrach połączenia (oznaczenie
            czasu, adres IP).
          </li>
          <li>
            Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą
            powiązanie danych w formularzu z adresem e-mail użytkownika
            wypełniającego formularz. W takim wypadku adres e-mail użytkownika
            pojawia się wewnątrz adresu url strony zawierającej formularz.
          </li>
          <li>
            Dane podane w formularzu są przetwarzane w celu wynikającym z
            funkcji konkretnego formularza, np. w celu dokonania procesu obsługi
            zgłoszenia serwisowego lub kontaktu handlowego, rejestracji usług
            itp. Każdorazowo kontekst i opis formularza w czytelny sposób
            informuje, do czego on służy.
          </li>
        </ol>

        <h2 className="text-xl font-bold mb-3">6. Logi Administratora</h2>
        <ol className="list-decimal list-inside mb-5">
          <li>
            Informacje zachowaniu użytkowników w serwisie mogą podlegać
            logowaniu. Dane te są wykorzystywane w celu administrowania
            serwisem.
          </li>
        </ol>
        <h2 className="text-xl font-bold mb-3">
          7. Istotne techniki marketingowe
        </h2>
        <ol className="list-decimal list-inside mb-5">
          <li>
            Operator stosuje analizę statystyczną ruchu na stronie, poprzez
            Google Analytics (Google Inc. z siedzibą w USA). Operator nie
            przekazuje do operatora tej usługi danych osobowych, a jedynie
            zanonimizowane informacje. Usługa bazuje na wykorzystaniu ciasteczek
            w urządzeniu końcowym użytkownika. W zakresie informacji o
            preferencjach użytkownika gromadzonych przez sieć reklamową Google
            użytkownik może przeglądać i edytować informacje wynikające z plików
            cookies przy pomocy narzędzia:
            https://www.google.com/ads/preferences/
          </li>
        </ol>

        <h2 className="text-xl font-bold mb-3">
          8. Informacja o plikach cookies
        </h2>
        <ol className="list-decimal list-inside mb-5">
          <li>Serwis korzysta z plików cookies.</li>
          <li>
            Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
            szczególności pliki tekstowe, które przechowywane są w urządzeniu
            końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze
            stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę
            strony internetowej, z której pochodzą, czas przechowywania ich na
            urządzeniu końcowym oraz unikalny numer.
          </li>
          <li>
            Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu
            pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.
          </li>
          <li>
            Pliki cookies wykorzystywane są w następujących celach:
            <ul className="list-disc list-inside pl-3">
              <li>
                utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki
                której użytkownik nie musi na każdej podstronie Serwisu ponownie
                wpisywać loginu i hasła;
              </li>
              <li>
                realizacji celów określonych powyżej w części "Istotne techniki
                marketingowe";
              </li>
            </ul>
          </li>
          <li>
            W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies:
            „sesyjne” (session cookies) oraz „stałe” (persistent cookies).
            Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w
            urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia
            strony internetowej lub wyłączenia oprogramowania (przeglądarki
            internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu
            końcowym Użytkownika przez czas określony w parametrach plików
            cookies lub do czasu ich usunięcia przez Użytkownika.
          </li>
          <li>
            Oprogramowanie do przeglądania stron internetowych (przeglądarka
            internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików
            cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą
            dokonać zmiany ustawień w tym zakresie.&nbsp;Przeglądarka
            internetowa umożliwia usunięcie plików cookies. Możliwe jest także
            automatyczne blokowanie plików cookies Szczegółowe informacje na ten
            temat zawiera pomoc lub dokumentacja przeglądarki internetowej.
          </li>
          <li>
            Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre
            funkcjonalności dostępne na stronach internetowych Serwisu.
          </li>
          <li>
            Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu
            wykorzystywane mogą być również przez współpracujące z operatorem
            Serwisu podmioty, w szczególności dotyczy to firm: Google (Google
            Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w USA),
            Twitter (Twitter Inc. z siedzibą w USA).
          </li>
        </ol>
        <h2 className="text-xl font-bold mb-3">
          9. Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?
        </h2>
        <ol className="list-decimal list-inside mb-5">
          <li>
            Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić
            ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików
            cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa,
            utrzymania preferencji użytkownika może utrudnić,&nbsp;a w skrajnych
            przypadkach może uniemożliwić korzystanie ze stron www
          </li>
          <li>
            W celu zarządzania ustawienia cookies wybierz z listy poniżej
            przeglądarkę internetową, której używasz i postępuj zgodnie z
            instrukcjami:
            <ul className="list-disc list-inside pl-3">
              <li>
                <a href="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history">
                  Edge
                </a>
              </li>
              <li>
                <a href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer">
                  Internet Explorer
                </a>
              </li>
              <li>
                <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">
                  Chrome
                </a>
              </li>
              <li>
                <a href="http://support.apple.com/kb/PH5042">Safari</a>
              </li>
              <li>
                <a href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek">
                  Firefox
                </a>
              </li>
              <li>
                <a href="http://help.opera.com/Windows/12.10/pl/cookies.html">
                  Opera
                </a>
              </li>
            </ul>
            <p>Urządzenia mobilne:</p>
            <ul className="list-disc list-inside pl-3">
              <li>
                <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">
                  Android
                </a>
              </li>
              <li>
                <a href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL">
                  Safari (iOS)
                </a>
              </li>
              <li>
                <a href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings">
                  Windows Phone
                </a>
              </li>
            </ul>
          </li>
        </ol>
      </section>
    </Layout>
  );
};

export default PrivatePolicyPage;

import React from "react";
import { Link } from "gatsby";
import RightArrow from "../ui/icons/RightArrow";

export default function Breadcrumbs({ pageTitle }) {
  return (
    <section className="container mx-auto px-5 pt-32 pb-24 relative z-20">
      <div className="container">
        <div className="breadcrumb-inner text-center">
          <h2 className="text-5xl text-white text-center font-bold mb-6">
            {pageTitle}
          </h2>
          <ul className="breadcrumbs flex justify-center">
            <li className="inline-flex items-center mr-4 text-gray-100">
              <Link className="mr-4" to="/">
                Home
              </Link>
              <RightArrow className="text-gray-100 fill-current" width="13" />
            </li>
            <li className="text-gray-100">{pageTitle}</li>
          </ul>
        </div>
      </div>
    </section>
  );
}
